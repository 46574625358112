<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false
      "
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <img :src="require('@/assets/images/svg/chat.svg')" width="64" height="64" />
          <!-- <feather-icon icon="MessageSquareIcon" size="64" /> -->
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36" variant="transparent"
                :src="activeChat.contact.avatar" :text="avatarText(activeChat.contact.fullName)"
                class="mr-1 cursor-pointer badge-minimal text-dark"
                badge             style="border-radius: 10px !important;"
                :badge-variant="
                  resolveAvatarBadgeVariant(activeChat.contact.status)
                "
                @click.native="shallShowActiveChatContactSidebar = true"
              />
              <h6 class="mb-0 font-weight-bold">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar" :profile-user-name="(profileUserDataMinimal.fullName || '')"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-button v-ripple.400="'rgba(3, 155, 229, 0.15)'" variant="outline-primary" class="btn-icon"
            @click="checkingRef">
            <u-icon icon="fi-rr-clip"></u-icon>
            <!-- <div style="height: 0px;width: 0px; overflow:hidden;"> -->
              <b-form-file ref="attachmentRef" v-model="fileInputName" accept=".jpg, .png, .gif, .pdf, .ppt, .xls, .doc" :hidden="true" plain @change="fileinputEvent" />
            <!-- </div> -->
          </b-button>
          <div class="container-fluid">
            <b-media style="    border: 1px solid #039be5; border-radius: 8px; padding-bottom: 4px; padding-top: 4px;"
              vertical-align="center" v-if="fileInputName != null" class="px-1 mr-1 align-items-center">
              <template #aside>
                <b-img :src="fileInputMessage" blank-color="#ccc" width="48" height="28" alt="attached file"
                  style="border-radius: 4px;" />
              </template>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="media-heading">
                  {{ fileInputName.name }}
                </h5>
                <i class="fi fi-rr-trash text-danger cursor-pointer" @click="removeAttachments"
                  style="font-size:18px"></i>
              </div>
            </b-media>

            <b-input-group
              class="input-group-merge form-send-message mr-1"
              v-else
            >
              <b-form-input           size="sm"
                v-model="chatInputMessage"
                placeholder="Enter your message"
              />
            </b-input-group>
          </div>
          <b-button variant="primary" type="submit">
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :new-contacts="newConversation"
        :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        "
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted, nextTick } from "@vue/composition-api";
import { useRouter } from '@core/utils/utils'
import { avatarText } from "@core/utils/filter";

import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BFormFile,
  BMedia,
  BImg,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from "@themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import chatStoreModule from "./chatStoreModule";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import Ripple from "vue-ripple-directive";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

export default {
  components: {
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormFile,
    BMedia,
    BImg,

    // 3rd Party
    VuePerfectScrollbar,
    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  directives: {
    Ripple,
  },
  methods: {
    checkingRef() {
      // console.log(this.$refs.attachmentRef);
      this.$refs.attachmentRef.$el.click();
    },
    removeAttachments() {
      this.fileInputName = null;
      this.fileInputMessage = "";
    },
  },
  created() {
    //changing when store value changed
    this.$store.watch(
      (state) => {
        return this.$store.state.mainUserCodes;
      },
      (newVal, oldVal) => {
        this.fetchChatAndContacts();
      },
      { deep: true }
    );
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = "app-chat";

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    // })
     const { route } = useRouter()
    const studentId = route.value.params.studentId

    const { resolveAvatarBadgeVariant } = useChat();

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      // const scrollEl = (refChatLogPS.value) ?  (refChatLogPS.value.$el || refChatLogPS.value) : null
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;

      // if (scrollEl) {
        scrollEl.scrollTop = scrollEl.scrollHeight;
      // }
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([]);
    const contacts = ref([]);
        const newConversation = ref([]);

    // console.log(store);
    const fetchChatAndContacts = () => {
      store.dispatch("app-chat/fetchChatsAndContacts").then((response) => {
        if (response) {
          chatsContacts.value = response.chatsList;
          contacts.value = [];
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = response.profileUser;
        }
      });
    };

    fetchChatAndContacts();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({});
    const chatInputMessage = ref("");
    const fileInputMessage = ref("");
    const fileInputName = ref(null);

     if (studentId) {
      //check if we already have conversation
      let contact = chatsContacts.value.find((c) => c.id === studentId);
      if (contact) {
        //active the chat of this contact
      } else {
            // console.log(studentId)

        store.dispatch("app-chat/fetchContactbyId",  { studentId:studentId } ).then((response) => {
          if (response) {
            newConversation.value.push(response)
            // activeChat.value.contact = contact;
            const chats = {
            userId: studentId,
            unseenMsgs: 0,
            chat: [],
          };
          activeChat.value = chats
          activeChat.value.contact = response
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }

    const openChatOfContact = (userId, role) => {

      // console.log(`this is contact id ${userId}`)
      // Reset send message input value
      chatInputMessage.value = "";
      fileInputMessage.value = null;
        fileInputName.value = null;

      store.dispatch("app-chat/getChat", { userId, role }).then((response) => {
        activeChat.value = response;

        // Set unseenMsgs to 0
        const contact = chatsContacts.value.find((c) => c.id === userId);
        if (contact) contact.chat.unseenMsgs = 0;
        activeChat.value.contact = contact;

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false;
    };
    const sendMessage = () => {
      if (!chatInputMessage.value) {
       if (!fileInputMessage.value) return;
     }

      const payload = {
        contactId: activeChat.value.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: profileUserDataMinimal.value.id,
        message: chatInputMessage.value,
        chatrole: activeChat.value.contact.role,
        files: fileInputMessage.value,
      };
      
      store.dispatch("app-chat/sendMessage", payload).then((response) => {
        const { newMessageData, chat } = response;
        //:TODO on new chat by id and all
        // ? If it's not undefined => New chat is created (Contact is not in list of chats)
        if (chat !== undefined) {
          activeChat.value = { chat, contact: activeChat.value.contact };
          chatsContacts.value.push({
            ...activeChat.value.contact,
            chat: {
              id: chat.id,
              lastMessage: newMessageData,
              unseenMsgs: 0,
            },
          });
          // var check if exists in newChat
          var checkContact = newConversation.value.find(
          (c) => c.id === activeChat.value.contact.id
        );
        if(checkContact) newConversation.value.pop()
        } else {
          // Add message to log
          // activeChat.value.chat.push(newMessageData);
          }

        // Reset send message input value
        chatInputMessage.value = "";
        fileInputName.value = null;
        fileInputMessage.value = "";

        // Set Last Message for active contact
        const contact = chatsContacts.value.find(
          (c) => c.id === activeChat.value.contact.id
        );
        contact.chat.lastMessage = newMessageData;

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      store.dispatch("app-chat/getProfileUser").then((response) => {
        profileUserData.value = response.data;
        shallShowUserProfileSidebar.value = true;
      });
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    const fileinputEvent = ($event) => {
      // console.log(event);
      var file = event.target.files[0];
      var user = firebase.auth().currentUser;
      if (file) {
        // fileInputName.value = file.name;
        var storageRef = firebase
          .storage()
          .ref("chat/msg_doc" + user.uid + file.name);
        storageRef.put(file).then(function(result) {
          storageRef.getDownloadURL().then(function(url) {
            var img = url;

            fileInputMessage.value = img;
            // console.log(img);
            // console.log(fileInputName.value);
          });
        });
        // thisIns.popupActive = false
      } else {
        fileInputMessage.value = "";
        fileInputName.value = "";
      }
    };

    return {
      // Filters
      // formatDate,
      // useChat
      resolveAvatarBadgeVariant,
      // Chat & Contacts
      chatsContacts,
      contacts,
      newConversation,
      fetchChatAndContacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      fileinputEvent,
      fileInputMessage,
      fileInputName,

      // Profile User Minimal Data
      profileUserDataMinimal,
      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,
      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,
      // UI
      perfectScrollbarSettings,
      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      avatarText
    };
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>