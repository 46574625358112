<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar
      size="48"
      square :text="avatarText(user.fullName)"
      :src="user.avatar"
      :badge="isChatContact"
      style="border-radius: 20px !important;"
      class="badge-minimal text-dark" 
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="white"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-25 font-weight-bold" style="font-size:14px">
        {{ user.fullName }}
      </h5>
      <p class="card-text text-truncate" style="font-size:12px">
        {{
          isChatContact
            ? user.chat.lastMessage.files
              ? "attachment"
              : user.chat.lastMessage.message
            : user.about
        }}
      </p>
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(user.chat.lastMessage.time, {
          hour: "numeric",
          minute: "numeric",
        })
      }}</small>
      <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant, avatarText };
  },
};
</script>

<style></style>
